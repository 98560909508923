/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { driver } from 'driver.js';
import { useContext, useEffect, useState } from 'react';

import { sampleAPI } from '../../../api';
import { PropertyType, StepTour } from '../../../api/enumerations';
import {
  CustomFormControl,
  CustomPatternFormat,
  CustomTextField,
  NumericTextField,
  PreffixNumericTextField,
  SelectTextField,
} from '../../../components/CustomInput';
import StandardMap from '../../../components/Maps/StandardMap';
import {
  CloseIcon,
  FilledButton,
  OutlinedButton,
  RoundedButton,
} from '../../../components/UI/Button';
import { DialogTitle } from '../../../components/UI/Typography';
import {
  IconAddMS,
  IconCloseMS,
  IconFactCheckMS,
  IconPhotoLibraryMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/sampleCreation';
import {
  selectConservation,
  selectStandard,
  selectStandard400K,
} from '../../../constants/selectOptions';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { driverConfig } from '../../../helpers/driver/config';
import { sampleAddNewElement } from '../../../helpers/driver/steps';
import useGeneral from '../../../hooks/useGeneral';
import useSearchHook from '../../../hooks/useSearchCep';
import { useTour } from '../../../hooks/useTour';
import { StyledTooltip } from '../styles';
import {
  BoldTypography,
  CoordBox,
  CoordGrid,
  Dialog,
  SectionBox,
  SectionSubtitle,
  SubmitBox,
} from './styles';
import UploadPictures from './UploadPictures';

type LatLngLiteral = google.maps.LatLngLiteral;

const initialPinPlace = {
  lat: -23.56162,
  lng: -46.65591,
};

interface AddElementProps {
  isDisabled: boolean;
  osNumber: number;
  updateSample: () => Promise<void>;
  highPrice: boolean | null | undefined;
  propertyType: PropertyType | undefined;
}

export function AddElement({
  isDisabled,
  osNumber,
  updateSample,
  highPrice,
  propertyType,
}: AddElementProps): JSX.Element {
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [addressComplement, setAddressComplement] = useState('');
  const [cep, setCep] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [uf, setUf] = useState('');
  const [privateArea, setPrivateArea] = useState(0);
  const [landArea, setLandArea] = useState(0);
  const [parkingLots, setParkingLots] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [conservation, setConservation] = useState(0);
  const [buildingStandard, setBuildingStandard] = useState(0);
  const [age, setAge] = useState(0);
  const [rooms, setRooms] = useState(0);
  const [condominiumFee, setCondominiumFee] = useState(0);
  const [advertiser, setAdvertiser] = useState('');
  const [advertiserContact, setAdvertiserContact] = useState('');
  const [propertyLink, setPropertyLink] = useState('');
  const [facadePicture, setFacadePicture] = useState<File>();
  const [pictureFiles, setPictureFiles] = useState<File[]>([]);
  const [searchMap, setSearchMap] = useState(true);
  const [checked, setChecked] = useState(false);
  const [acceptedAt, setAcceptedAt] = useState('');
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>(initialPinPlace);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isAddressEdited, setIsAddressEdited] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const dialog = useGeneral();
  const { handleSearchCep } = useSearchHook();

  const {
    setTutorialStep,
    tourCompletion,
    setTourCompletion,
    setTourOn,
    setTourSelection,
    setToBeContinued,
  } = useTour();

  const housePropertyType = propertyType === PropertyType.HOUSE;

  const clearValues = (): void => {
    setPrivateArea(0);
    setLandArea(0);
    setParkingLots(0);
    setAddress('');
    setAddressNumber('');
    setAddressComplement('');
    setCep('');
    setCity('');
    setUf('');
    setDistrict('');
    setSellingPrice(0);
    setPropertyLink('');
    setAge(0);
    setRooms(0);
    setCondominiumFee(0);
    setBuildingStandard(0);
    setConservation(0);
    setAdvertiser('');
    setAdvertiserContact('');
    setFacadePicture(undefined);
    setPictureFiles([]);
    setChecked(false);
    setAcceptedAt('');
    setPinPlace(initialPinPlace);
  };

  const createElement = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (isAddressEdited) {
      setSnackbarMessage('Pesquise a localização no mapa antes de salvar.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (sellingPrice === 0 || privateArea === 0 || rooms === 0) {
      setSnackbarMessage('Confirme o preenchimento dos itens obrigatórios');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (propertyType === PropertyType.HOUSE && landArea === 0) {
      setSnackbarMessage('Confirme o preenchimento dos itens obrigatórios');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (buildingStandard === 0 || conservation === 0) {
      setSnackbarMessage(
        'Estado de conservação e padrão construtivo são obrigatórios'
      );
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const description = {
      zipcode: cep,
      street: address,
      number: addressNumber,
      address_complement: addressComplement,
      city,
      district,
      state: uf,
      area: privateArea,
      land_area: propertyType === PropertyType.HOUSE ? landArea : null,
      parking_spaces: parkingLots,
      sell_price: sellingPrice,
      bedrooms: rooms,
      condominium_fee: condominiumFee,
      advertiser: {
        name: advertiser,
        phone: advertiserContact,
      },
      link: propertyLink,
      latitude: pinPlace.lat,
      longitude: pinPlace.lng,
      level: 1,
      accepted: checked,
      accepted_at: acceptedAt,
    };

    const formData = new FormData();
    pictureFiles.map((file) => formData.append('pictures', file));
    formData.append('work_order_id', osNumber.toString());
    formData.append('constructive_standard', buildingStandard.toString());
    formData.append('preservation_state', conservation.toString());
    formData.append('age', age.toString());
    formData.append('description', JSON.stringify(description));

    if (facadePicture) {
      formData.append('facade_picture', facadePicture);
    }
    if (pictureFiles.length > 0) {
      pictureFiles.map((file) => formData.append('pictures', file));
    }

    try {
      setSubmitLoading(true);
      const response = await sampleAPI.addNewElement(formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Elemento adicionado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      clearValues();
      dialog.handleClose();
      updateSample();
      setSubmitLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSubmitLoading(false);
    }
  };

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setCep(addressData.cep);
      setUf(addressData.uf);
      setCity(addressData.city);
      setDistrict(addressData.district);
      setAddress(addressData.address);
    }
    setIsAddressEdited(true);
  };

  useEffect(() => {
    if (checked) {
      const dateTime = new Date().toLocaleString('pt-br');
      setAcceptedAt(dateTime);
    }
  }, [checked]);

  useEffect(() => {
    if (dialog.open) {
      const driverObj = driver({
        ...driverConfig,
        steps: sampleAddNewElement,
        onNextClick: () => {
          const activeIndex = driverObj.getActiveIndex() || 0;
          if (driverObj.isLastStep()) {
            setTourCompletion((prev) => {
              const newTourCompletion = { ...prev };
              newTourCompletion.sampleCreate[1].step = activeIndex;
              newTourCompletion.sampleCreate[1].complete = true;
              newTourCompletion.lastStepSeen = StepTour.SAMPLECREATEFINISH;
              return newTourCompletion;
            });
            setTutorialStep(StepTour.SAMPLECREATEFINISH);
            setTourOn(true);
            driverObj.destroy();
          }
          driverObj.moveNext();
        },
        onCloseClick: () => {
          setTourSelection(false);
          setToBeContinued(false);
          driverObj.destroy();
        },
        onDestroyStarted: () => {
          setTourSelection(false);
          setToBeContinued(false);
          driverObj.destroy();
        },
      });
      setTimeout(() => {
        if (
          !tourCompletion.sampleCreate[1].complete &&
          !tourCompletion.skipTour.sampleCreate
        ) {
          setTutorialStep(StepTour.SAMPLECREATESTART);
          driverObj.drive();
        }
      }, 700);
    }
  }, [dialog.open]);

  return (
    <>
      <StyledTooltip
        title="Quantidade máxima de elementos alcançada"
        placement="top"
        disableHoverListener={!isDisabled}
      >
        <span>
          <RoundedButton
            id="add-element"
            model="dark"
            disabled={isDisabled}
            disableTouchRipple
            onClick={dialog.handleOpen}
            sx={{ marginTop: '40px' }}
          >
            {IconAddMS}
            {Constants.addElement}
          </RoundedButton>
        </span>
      </StyledTooltip>
      <Dialog
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="add element dialog"
      >
        <CloseIcon id="close-icon" onClick={dialog.handleClose}>
          {IconCloseMS}
        </CloseIcon>
        <Box component="form" id="add-new-element" onSubmit={createElement}>
          <SectionBox>
            <DialogTitle>
              {IconFactCheckMS}
              {Constants.newElement}
            </DialogTitle>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6} className="ad-data">
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <CustomPatternFormat
                      required
                      id="cep"
                      label="CEP"
                      value={cep}
                      setValue={setCep}
                      format="#####-###"
                      helper={verifyCep}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <CustomTextField
                      required
                      id="address"
                      label="logradouro"
                      value={address}
                      setValue={setAddress}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      required
                      id="address-number"
                      label="número"
                      value={addressNumber}
                      setValue={setAddressNumber}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <CustomTextField
                      id="address-complement"
                      label="complemento"
                      value={addressComplement}
                      setValue={setAddressComplement}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      required
                      id="district"
                      label="bairro"
                      value={district}
                      setValue={setDistrict}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      required
                      id="city"
                      label="cidade"
                      value={city}
                      setValue={setCity}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      required
                      id="uf"
                      label="estado"
                      value={uf}
                      setValue={setUf}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumericTextField
                      id="total-area"
                      label={
                        propertyType === PropertyType.APARTMENT
                          ? Constants.privateArea
                          : Constants.constructedArea
                      }
                      suffix=" m²"
                      decimalSeparator=","
                      decimalScale={2}
                      maxLength={18}
                      value={privateArea}
                      setValue={setPrivateArea}
                    />
                  </Grid>
                  {housePropertyType && (
                    <Grid item xs={4}>
                      <NumericTextField
                        id="land-area"
                        label={Constants.landArea}
                        suffix=" m²"
                        decimalSeparator=","
                        decimalScale={2}
                        maxLength={18}
                        value={landArea}
                        setValue={setLandArea}
                      />
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <NumericTextField
                      required
                      id="parking-lots"
                      label="vagas de garagem"
                      suffix=" vagas"
                      maxLength={9}
                      value={parkingLots}
                      setValue={setParkingLots}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <PreffixNumericTextField
                      required
                      id="selling-price"
                      label="preço de venda"
                      prefix="R$ "
                      decimalSeparator=","
                      decimalScale={2}
                      maxLength={18}
                      value={sellingPrice}
                      setValue={setSellingPrice}
                    />
                  </Grid>
                  <Grid item xs={housePropertyType ? 4 : 8}>
                    <SelectTextField
                      required
                      id="conservation-age-estimate"
                      label="estado de conservação do imóvel"
                      value={conservation}
                      setValue={setConservation}
                      selectOptions={selectConservation()}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SelectTextField
                      required
                      id="standard-age-estimate"
                      label="padrão construtivo"
                      value={buildingStandard}
                      setValue={setBuildingStandard}
                      selectOptions={
                        highPrice ? selectStandard400K() : selectStandard()
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumericTextField
                      required
                      id="property-age-estimate"
                      label="idade do imóvel"
                      suffix=" anos"
                      maxLength={9}
                      value={age}
                      setValue={setAge}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumericTextField
                      required
                      id="rooms"
                      label="quartos"
                      suffix=" quartos"
                      maxLength={12}
                      value={rooms}
                      setValue={setRooms}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <PreffixNumericTextField
                      id="condominium-fee"
                      label="valor do condomínio"
                      prefix="R$ "
                      decimalSeparator=","
                      decimalScale={2}
                      maxLength={18}
                      value={condominiumFee}
                      setValue={setCondominiumFee}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      id="advertiser-name"
                      label="nome anunciante"
                      value={advertiser}
                      setValue={setAdvertiser}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomPatternFormat
                      id="advertiser-telephone"
                      label="contato anunciante"
                      value={advertiserContact}
                      setValue={setAdvertiserContact}
                      format="(##) #####-####"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      id="property-link"
                      label="link do imóvel"
                      value={propertyLink}
                      setValue={setPropertyLink}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} className="grid-map">
                <Grid container>
                  <Grid item xs={12}>
                    <StandardMap
                      searchMap={searchMap}
                      address={`${address} ${addressNumber} ${district} ${city}`}
                      pinPlace={pinPlace}
                      setPinPlace={setPinPlace}
                    />
                  </Grid>
                  <CoordGrid item xs={12}>
                    <CoordBox>
                      <BoldTypography>{Constants.lat}</BoldTypography>
                      <Typography>{pinPlace.lat}</Typography>
                    </CoordBox>
                    <CoordBox>
                      <BoldTypography>{Constants.lng}</BoldTypography>
                      <Typography>{pinPlace.lng}</Typography>
                    </CoordBox>
                  </CoordGrid>
                  <Grid item xs={12}>
                    <RoundedButton
                      onClick={() => {
                        setSearchMap(!searchMap);
                        setIsAddressEdited(false);
                      }}
                    >
                      {Constants.searchMap}
                    </RoundedButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SectionBox>
          <SectionBox>
            <SectionSubtitle>
              {IconPhotoLibraryMS}
              {Constants.addPhotos}
            </SectionSubtitle>
            <UploadPictures
              facadePicture={facadePicture}
              setFacadePicture={setFacadePicture}
              pictureFiles={pictureFiles}
              setPictureFiles={setPictureFiles}
            />
            <CustomFormControl
              required
              label={Constants.addElementCheckbox}
              isChecked={checked}
              setIsChecked={setChecked}
            />
          </SectionBox>
          <SubmitBox>
            <OutlinedButton onClick={dialog.handleClose}>
              {Constants.cancel}
            </OutlinedButton>
            <FilledButton
              className="add-element"
              form="add-new-element"
              type="submit"
              disabled={submitLoading}
            >
              {submitLoading ? (
                <CircularProgress size={22} />
              ) : (
                Constants.addElement
              )}
            </FilledButton>
          </SubmitBox>
        </Box>
      </Dialog>
    </>
  );
}
